<template>
  <div class="s_main_view_store g-flex-align-start">
    <div class="s-main-store-left">
      <!-- 头部信息 -->
      <!-- <div class="s-main-store-left-top g-flex-align-center">
        <p class="s-main-store-left-top-title">客服</p>
        <ul class="s-main-store-left-top-list g-flex-align-center">
          <li class="s-main-store-left-top-list-item g-flex-align-center">
            <i class="iconfont iconweixin2"></i>
            <p class="s-main-store-left-top-list-item-title">微信:</p>
            <span class="s-main-store-left-top-list-item-value">{{formData.kefu.wechat}}</span>
            <div class="s-main-store-left-top-list-item-btn" @click="copyClick(formData.kefu.wechat)">复制</div>
          </li>
          <li class="s-main-store-left-top-list-item g-flex-align-center">
            <i class="iconfont iconQQ"></i>
            <p class="s-main-store-left-top-list-item-title">QQ:</p>
            <span class="s-main-store-left-top-list-item-value">{{formData.kefu.qq}}</span>
            <div class="s-main-store-left-top-list-item-btn g-flex-align-center" @click="copyClick(formData.kefu.qq)">复制</div>
          </li>
        </ul>
      </div> -->
      <div class="s-main-store-left-middle">
        <h3 class="s-main-store-left-middle-title">订单管理</h3>
        <ul class="s-main-store-left-middle-list g-flex-align-center">
          <!-- 待接单 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-wait-receive" @click="storeTaskItemClick('10')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconjiedan"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">待接单</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s10" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>
          <!-- 待审核 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-wait-shenhe" @click="storeTaskItemClick('20')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconshenhe"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">待审核</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s20" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>
          <!-- 操作中 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-ing" @click="storeTaskItemClick('30')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconcaozuozhong"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">操作中</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s30" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>

        </ul>
        <ul class="s-main-store-left-middle-list g-flex-align-center s-main-store-left-middle-list-second">
          <!-- 待返款 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-wait-pay" @click="storeTaskItemClick('40')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconfankuanxinxi"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">待返款</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s40" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>
          <!-- 待收货 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-wait-shouhuo" @click="storeTaskItemClick('50')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconshouhuo"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">待收货</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s50" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>
          <!-- 待完成 -->
          <li class="s-main-store-left-middle-list-item s-main-store-left-middle-item-wait-confirm" @click="storeTaskItemClick('60')">
            <div class="s-main-store-left-middle-list-item-left">
              <i class="iconfont iconqueren"></i>
            </div>
            <div class="s-main-store-left-middle-list-item-right">
              <p class="s-main-store-left-middle-list-item-right-title">待完成</p>
              <animated-number class="s-main-store-left-middle-list-item-right-num" :value="formData.taskNums.s60" :formatValue="formatToPrice" :duration="duration"/>
            </div>
          </li>
          
        </ul>
      </div>
      <!-- 底部信息 -->
      <div class="s-main-store-left-bottom g-flex-column g-flex-align-center">
        <div class="s-main-store-left-bottom-content g-flex-align-center">
        <!-- 公告列表 -->
        <div class="s-main-store-left-bottom-notice g-flex-column g-flex-align-center">
          <h3 class="s-main-store-left-bottom-notice-title">平台公告</h3>
          <ul class="s-main-store-left-bottom-notice-list g-self-notice-scrollbar">
            <li class="s-main-store-left-bottom-notice-list-item g-flex-column" v-for="(item, index) in bullentList" :key="index" @click="noticeItemClick(item)">
              <div class="s-main-store-left-bottom-notice-list-item-top g-flex-align-center">
                <p>{{item.title}}</p>
                <i class="iconfont iconxiangyou"></i>
              </div>
              <span class="s-main-store-left-bottom-notice-list-item-time">{{formatDate(item.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
            </li>
          </ul>
        </div>
        <!-- 公告弹窗 -->
        <main-view-store-notice-pop ref="MainViewStoreNoticePop"/>

        <div class="s-main-store-left-bottom-list g-flex-align-center">
          <!-- 用户名 -->
          <div class="s-main-store-left-bottom-list-item g-flex-column g-flex-align-center">
            <div class="s-main-store-left-bottom-list-item-icon s-main-store-left-bottom-list-item-icon-username">
              <i class="iconfont iconren-01"></i>
            </div>
            <p class="s-main-store-left-bottom-list-item-title">用户名</p>
            <p class="s-main-store-left-bottom-list-item-value">{{formData.user.mobile}}</p>
          </div>
          <!-- IP -->
          <div class="s-main-store-left-bottom-list-item g-flex-column g-flex-align-center">
            <div class="s-main-store-left-bottom-list-item-icon s-main-store-left-bottom-list-item-icon-ip">
              <i class="iconfont iconIPdizhi"></i>
            </div>
            <p class="s-main-store-left-bottom-list-item-title">IP</p>
            <p class="s-main-store-left-bottom-list-item-value">{{formData.user.login_ip}}</p>
          </div>
          <!-- IP地址 -->
          <div class="s-main-store-left-bottom-list-item g-flex-column g-flex-align-center">
            <div class="s-main-store-left-bottom-list-item-icon  s-main-store-left-bottom-list-item-icon-address">
              <i class="iconfont icondizhi"></i>
            </div>
            <p class="s-main-store-left-bottom-list-item-title">IP地址</p>
            <p class="s-main-store-left-bottom-list-item-value">{{formData.user.login_address}}</p>
          </div>
          <!-- 创建时间 -->
          <div class="s-main-store-left-bottom-list-item g-flex-column g-flex-align-center">
            <div class="s-main-store-left-bottom-list-item-icon  s-main-store-left-bottom-list-item-icon-login">
              <i class="iconfont iconshijian"></i>
            </div>
            <p class="s-main-store-left-bottom-list-item-title">登录时间</p>
            <p class="s-main-store-left-bottom-list-item-value">{{ formatDate(formData.user.login_time, 'yyyy-MM-dd hh:mm')}}</p>
          </div>
        </div>
        </div>


        <div class="s-main-store-left-bottom-btn g-flex-align-center g-flex-justify-center" @click="fabuClick"> 
          <span>去发布</span>
          <i class="iconfont iconxiayige"></i>
        </div>
      </div>
    </div>
    <div class="s-main-store-right">
      <div class="s-main-store-right-top g-flex-column g-flex-align-center">
        <img src="/img/logo2.png" alt="">
        <p class="s-main-store-right-top-tel">{{formData.user.mobile}}</p>
        <div class="s-main-store-right-top-yaoqing g-flex-align-center">
          <div class="g-flex-align-center g-flex-1">
            <span class="s-main-store-right-top-yaoqing-title">邀请码：</span>
            <p class="s-main-store-right-top-yaoqing-value">{{formData.user.spread_code}}</p>
          </div>
          <div class="s-main-store-right-top-btn g-flex-align-center g-flex-justify-center" @click="copyClick(formData.user.shop_spread )">复制邀请链接</div>
        </div>
      </div>
      <div class="s-main-store-right-middle">
        <!-- 本金余额 -->
        <div class="s-main-store-right-middle-item g-flex-column">
          <div class="s-main-store-right-middle-item-top g-flex-align-center">
          <div class="s-main-store-right-dot"></div>
            <p class="s-main-store-right-middle-item-top-title">本金余额</p>
          </div>
          <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
            <p class="s-main-store-right-middle-item-bottom-value">{{ formData.user.principal  }}</p>
            <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            <span class="s-main-store-right-middle-item-top-recharge" @click="$router.push({ path: '/moneystore/moneystorerecharge'})">去充值</span>
          </div>
        </div>
        <!-- 任务数量 订单数量 -->
        <!-- <div class="g-flex-align-center">
          
          <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">任务数量</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">200</p>
              <span class="s-main-store-right-middle-item-bottom-dan">个</span>
            </div>
          </div>

           <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
              <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">订单数量</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">200</p>
              <span class="s-main-store-right-middle-item-bottom-dan">个</span>
            </div>
          </div>
        </div> -->

      </div>

      <div class="s-main-store-right-middle s-main-store-right-bottom">
        <div class="g-flex-align-center">
        <!-- 今日支出 -->
          <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">今日支出</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{ formData.user.task_money }}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            </div>
          </div>
        </div>
      </div>

      <div class="s-main-store-right-middle s-main-store-right-bottom">
        <div class="g-flex-align-center">
          <!-- 昨日充值 -->
          <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">昨日充值</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{ formData.user.recharge_money  }}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            </div>
          </div>
        </div>
      </div>


        <!--推广  -->
       <div class="s-main-store-right-middle s-main-store-right-bottom">
        <!-- 推广收入 -->
        <div class="g-flex-align-center">
          <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">推广收入</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{formData.user.spread_money}}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            </div>
          </div>
          <!-- 推广注册人数 -->
           <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">推广注册人数</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{formData.user.spread_nums}}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">人</span>
            </div>
          </div>
        </div>
        <!-- 今日推广收入 -->
        <div class="g-flex-align-center">
          <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">今日推广收入</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{formData.user.today_spread}}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            </div>
          </div>
           <div class="s-main-store-right-middle-item g-flex-column">
            <div class="s-main-store-right-middle-item-top g-flex-align-center">
            <div class="s-main-store-right-dot"></div>
              <p class="s-main-store-right-middle-item-top-title">本月推广收入</p>
            </div>
            <div class="s-main-store-right-middle-item-bottom g-flex-align-end">
              <p class="s-main-store-right-middle-item-bottom-value">{{formData.user.month_spread }}</p>
              <span class="s-main-store-right-middle-item-bottom-dan">元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiShopInfoTotal, apiGetMainShopBulletinList } from '@/utils/api.js'
import AnimatedNumber from "animated-number-vue";
import { formatDate } from '@/utils/index.js'
import MainViewStoreNoticePop from './MainViewStoreNoticePop.vue'
export default {
   components: {
    AnimatedNumber, MainViewStoreNoticePop
  },
  data() {
    return {
      duration: 1000,
      bullentList: [],
      formData: {
        kefu: {
          qq: "",
          wechat: ""
        },
        taskNums: {
          s10: 0,
          s20: 0,
          s30: 0,
          s40: 0,
          s50: 0,
          s60: 0,
        },
        user: {
          create_time: 0,
          id: "",
          login_address: "",
          login_ip: "",
          login_time: 0,
          mobile: "",
          nick_name: "",
          principal: "",
          recharge_money: 0,
          spread_code: "",
          spread_money: 0,
          spread_nums: 0,
          task_money: ""
        }
      }
    }
  },
  created() {
    this.apiShopInfoTotalHandel()
    this.apiGetShopBulletinListHandel()
  },
  methods: {
    formatDate,
    formatToPrice(value) {
        return `${value.toFixed(0)}`
    },  

    // 公告点击
    noticeItemClick(item) {
      this.$refs.MainViewStoreNoticePop.onShow(item)
    },

    // 获取首页数据
    async apiShopInfoTotalHandel() {
      const { success, data } = await apiShopInfoTotal()
      if(!success) return
      this.formData = data.data
      console.log(this.formData)
    },

    // 获取公告列表
    async apiGetShopBulletinListHandel() {
      const { success, data } = await apiGetMainShopBulletinList()
      if(!success) return
      this.bullentList = data.rows
    },
    // 复制
    copyClick(message) {
      this.$copyText(message).then(() => {
        this.$message.success({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$message.error('复制失败,请重试');
      })
    },
    storeTaskItemClick(status) {
      this.$router.push({ path: '/issuetask/tasklistorder', query: { status: status } })
    },
    fabuClick() {
      this.$router.push({ path: '/issuetask/taskissuedianfu' })
    }
  }
}
</script>

<style lang='scss'>
.s_main_view_store {
  height: 100%;
  padding: 0 10px;
  background: #F3F3F3;
  .s-main-store-left {
    flex: 4;
    padding-right: 20px;
    // 左侧头部
    .s-main-store-left-top {
      background: #D8EAFF;
      border-radius: 5px;
      padding: 15px 30px;
      .s-main-store-left-top-title {
        color: $blue;
        font-size: 20px;
        font-weight: bold;
      }
      .s-main-store-left-top-list {
        color: $blue;
        .s-main-store-left-top-list-item {
          &:nth-last-of-type(1) {
            padding-left: 20px;
          }
          .iconfont {
            font-size: 18px;
            padding-left: 10px;
          }
          .s-main-store-left-top-list-item-title {
            font-size: 18px;
            padding-left: 10px;
          }
          .s-main-store-left-top-list-item-value {
            font-size: 18px;
            padding-left: 10px;
          }
          .s-main-store-left-top-list-item-btn {
            user-select: none;
            font-size: 14px;
            margin-left: 10px;
            padding: 5px 10px;
            background: $white;
            cursor: pointer;
            border: 1px solid $blue;
            border-radius: 5px;
          }
        }
      }
    }
    // 左侧中间
    .s-main-store-left-middle {
      .s-main-store-left-middle-title {
        color: $blue;
        font-size: 18px;
        font-weight: bold;
      }
      .s-main-store-left-middle-list {
        .s-main-store-left-middle-list-item {
          border-radius: 15px;
          margin-left: 40px;
          min-height: 140px;
          max-height: 140px;
          cursor: pointer;
          flex: 1;
          display: flex;
          align-items: flex-end;
          &:hover {
            box-shadow: 0px 10px 18px 8px rgba(194, 197, 251, 0.2);
          }
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &.s-main-store-left-middle-item-wait-receive {
            background: #6F85FB;
          }
          &.s-main-store-left-middle-item-ing {
            background: #FB8E8E;
          }
          &.s-main-store-left-middle-item-wait-pay {
            background: #38D0B4;
          }
          &.s-main-store-left-middle-item-wait-shenhe {
            background: #FEC558;
          }
          &.s-main-store-left-middle-item-wait-confirm {
            background: #F86384;
          }
          &.s-main-store-left-middle-item-wait-shouhuo {
            background: #51B3EB;
          }
          
          .s-main-store-left-middle-list-item-left {
            .iconfont {
              color: rgba($color: #FFFFFF, $alpha: 0.5);
              font-size: 120px;
            }
          }
          .s-main-store-left-middle-list-item-right {
            flex: 1;
            align-self: center;
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            .s-main-store-left-middle-list-item-right-title {
              font-size: 30px;
              font-weight: bold;
            }
            .s-main-store-left-middle-list-item-right-num {
              font-size: 32px;
              padding-top: 10px;
            }
          }
        }
        &.s-main-store-left-middle-list-second {
          margin-top: 20px;
        }

      }
    }
    // 左侧底部
    .s-main-store-left-bottom {
      padding-top: 30px;
      padding-bottom: 20px;
      width: 100%;
      .s-main-store-left-bottom-content {
        width: 100%;
        min-height: 300px;
        max-height: 300px;
        .s-main-store-left-bottom-notice {
          min-height: 300px;
          max-height: 300px;
          overflow: hidden;
          flex: 1;
          background: #FFFFFF;
          border-radius: 15px;
          margin-right: 20px;
          .s-main-store-left-bottom-notice-title {
            color: $blue;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 0;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;
            align-self: stretch;
          }
         .s-main-store-left-bottom-notice-list {
            align-self: stretch;
            flex:1;
            overflow-y: auto;
            padding: 10px;
            .s-main-store-left-bottom-notice-list-item {
              cursor: pointer;
              box-shadow: 0px 0px 7px 0px rgba(64, 158, 255, 0.35);
              border-radius: 15px;
              margin-top: 10px;
              min-height: 70px;
              max-height: 70px;
              padding: 6px 14px;
              &:nth-of-type(1) {
                margin-top: 0;
              }
              .s-main-store-left-bottom-notice-list-item-top {
                p {
                  flex: 1;
                  font-size: 14px;
                  color: #030303;
                  @include textEllipsis(2);
                  padding-right: 10px;
                  line-height: 18px;
                }
                .iconfont {
                  font-size: 20px;
                  color: $blue;
                }
              }
              .s-main-store-left-bottom-notice-list-item-time {
                font-size: 14px;
                color: #969696;
                margin-top: 6px;
              }
            }
          }
        }
        .s-main-store-left-bottom-list {
          flex: 1;
          padding: 30px 0;
          background: $white;
          align-self: stretch;
          border-radius: 15px;
          flex-wrap: wrap;
          .s-main-store-left-bottom-list-item {
            width: 50%;
            &:nth-of-type(3), &:nth-of-type(4) {
              margin-top: 20px;
            }
            .s-main-store-left-bottom-list-item-icon {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .iconfont {
                font-size: 30px;
                color: $white;
              }
              &.s-main-store-left-bottom-list-item-icon-username {
                background: #FEC558;
              }
              &.s-main-store-left-bottom-list-item-icon-ip {
                background: #F86384;
              }
              &.s-main-store-left-bottom-list-item-icon-address {
                background: #51B3EB;
              }
              &.s-main-store-left-bottom-list-item-icon-login {
                background: #38D0B4;
              }
            }
            .s-main-store-left-bottom-list-item-title {
              padding-top: 15px;
              font-size: 18px;
              color: #5F5F5F;
              letter-spacing: 1px;
            }
            .s-main-store-left-bottom-list-item-value {
              padding-top: 15px;
              font-size: 16px;
              color: #010101;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }
      }

      .s-main-store-left-bottom-btn {
        width: 440px;
        height: 60px;
        border-radius: 30px;
        color: $white;
        background: $blue;
        font-size: 24px;
        font-weight: bold;
        margin-top: 50px;
        cursor: pointer;
        &:hover {
          background: #5aa7f7;
        }
        span {
          padding: 0 10px;
        }
        .iconfont {
          font-size: 18px;
        }
      }
    }
  }

  .s-main-store-right {
    flex: 1;
    height: 100%;
    background: $white;
    border-radius: 8px;
    padding: 0 20px;
    min-width: 330px;
    max-width: 360px;
    .s-main-store-right-dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $blue;
    }
    .s-main-store-right-top {
      padding-top: 30px;
      img {
        height: 70px;
        object-fit: contain;
      }
      .s-main-store-right-top-tel {
        color: #000000;
        font-size: 20px;
        padding-top: 20px;
        font-weight: bold;
      }
      .s-main-store-right-top-yaoqing {
        align-self: stretch;
        background: #D8EAFF;
        padding: 15px;
        border-radius: 10px;
        margin-top: 20px;
        .s-main-store-right-top-yaoqing-title {
          font-size: 16px;
          color: #000000;
        }
        .s-main-store-right-top-yaoqing-value {
          font-size: 22px;
          color: #000000;
          font-weight: bold;
        }
        .s-main-store-right-top-btn {
          width: 96px;
          height: 30px;
          font-size: 14px;
          color: $white;
          background: $blue;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .s-main-store-right-middle {
      margin-top: 10px;
      .s-main-store-right-middle-item {
        padding-top: 30px;
        flex: 1;
        .s-main-store-right-middle-item-top  {
          .s-main-store-right-middle-item-top-title {
            font-size: 18px;
            color: #000000;
            padding-left: 6px;
          }
        }
        .s-main-store-right-middle-item-bottom {
          padding-left: 20px;
          padding-top: 20px;
          font-size: 16px;
          line-height: 12px;
          .s-main-store-right-middle-item-bottom-value {
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
          }
          .s-main-store-right-middle-item-bottom-dan {
            padding-left: 10px;
            font-size: 18px;
          }
          .s-main-store-right-middle-item-top-recharge {
            color: $blue;
            margin-left: 20px;
            font-size: 18px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .s-main-store-right-middle-item-btn {
          user-select: none;
          font-size: 14px;
          margin-left: 14px;
          margin-top: 20px;
          width: 60px;
          height: 30px;
          background: $white;
          cursor: pointer;
          border: 1px solid $blue;
          border-radius: 5px;
          color: $blue;
          align-self: start;
        }
      }
    }

    .s-main-store-right-bottom {
      margin-top: 30px;
    }
  }
}
</style>