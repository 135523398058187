<template>
 <div class="s_main_view_store_notice_pop">
   <el-dialog title="公告"  class="s-main-view-store-notice-pop-dialog"  :visible.sync="show"  width="600px" center>
     <div class="s-main-view-store-notice-pop-dialog-top g-flex-align-center">
       <p>{{formData.title}}</p>
       <span>{{ formatDate(formData.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
     </div>
    <div class="s-main-view-store-notice-pop-dialog-content" v-html="formData.content">
      
    </div>
  </el-dialog>
 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
export default {
  data() {
    return {
      show: false,
      formData: {
        title: '',
        modify_time: 0,
        content: ''
      },
    }
  },
  methods: {
    formatDate,
    onShow(data) {
      this.formData = data
      this.show = true
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
.s_main_view_store_notice_pop {
  .s-main-view-store-notice-pop-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #EEEEEE;
      margin: 0 10px;
      .el-dialog__title {
        color: $blue;
        font-weight: bold;
      }
      .el-dialog__headerbtn {
        .el-dialog__close {
          color: $blue;
          font-size: 20px;
        }
      }
    }
    .s-main-view-store-notice-pop-dialog-top {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 30px;
      p {
        flex: 1;
        padding-right: 10px;
        color: #030303;
        font-weight: bold;
      }
      span {
        color: #969696;
        font-size: 14px;
      }
    }
    .s-main-view-store-notice-pop-dialog-content {
      line-height: 30px;
    }
  }
}
</style>