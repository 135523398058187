<template>
  <div class="s_main_view_admin">
    <div class="s-main-view-admin-top g-flex-justify-end">
      <ul class="s-main-view-admin-top-list g-flex-align-center">
        <li class="s-main-view-admin-top-item g-flex-align-center g-flex-justify-center" @click="timeSelect(item,index)" :class="index == tabIndex ? 's-main-view-admin-top-item-active': ''" v-for="(item, index) in timeList" :key="index">
          <span class="s-main-view-admin-top-item-title">{{item.name}}</span>
        </li>
        <div class="s-main-view-admin-top-time-pick" v-show="tabIndex == 4" >
          <el-date-picker size="small"  :default-time="['00:00:00', '23:59:59']" v-model="form.date_time" type="datetimerange" @change="timePinkerChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </div>
      </ul>
    </div>
    <div class="s-main-view-admin-middle">
      <ul class="s-main-view-admin-middle-list g-flex-align-center">
        <li class="s-main-view-admin-middle-item g-flex-column">
          <p class="s-main-view-admin-middle-item-title">任务数量/个</p>
          <div class="s-main-view-admin-middle-item-num g-flex-align-center">
            <span class="s-main-view-admin-middle-item-num-val">{{formData.taskNums}}</span>
            <img src="/img/admintop1.png" alt="">
          </div>
        </li>
        <li class="s-main-view-admin-middle-item g-flex-column">
          <p class="s-main-view-admin-middle-item-title">任务订单/个</p>
          <div class="s-main-view-admin-middle-item-num g-flex-align-center">
            <span class="s-main-view-admin-middle-item-num-val">{{formData.taskOrderRelease}}</span>
            <img src="/img/admintop2.png" alt="">
          </div>
        </li>
        <li class="s-main-view-admin-middle-item g-flex-column">
          <p class="s-main-view-admin-middle-item-title">已完成订单/个</p>
          <div class="s-main-view-admin-middle-item-num g-flex-align-center">
            <span class="s-main-view-admin-middle-item-num-val">{{formData.taskOrderEnd}}</span>
            <img src="/img/admintop3.png" alt="">
          </div>
        </li>
        <!-- <li class="s-main-view-admin-middle-item g-flex-column">
          <p class="s-main-view-admin-middle-item-title">已取消订单/个</p>
          <div class="s-main-view-admin-middle-item-num g-flex-align-center">
            <span class="s-main-view-admin-middle-item-num-val">{{formData.taskOrdercancel}}</span>
            <img src="/img/admintop1.png" alt="">
          </div>
        </li>
        <li class="s-main-view-admin-middle-item g-flex-column">
          <p class="s-main-view-admin-middle-item-title">未完成订单/个</p>
          <div class="s-main-view-admin-middle-item-num g-flex-align-center">
            <span class="s-main-view-admin-middle-item-num-val">{{formData.taskUndone}}</span>
            <img src="/img/admintop4.png" alt="">
          </div>
        </li> -->
      </ul>
    </div>
    <div class="s-main-view-admin-bottom">
      <ul class="s-main-view-admin-bottom-list g-flex-align-center">
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom1.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.profit | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">总盈利</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom2.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.refund_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">补款金额</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom4.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.buy_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">买家返款佣金</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom2.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.return_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">推广返款佣金</p>
        </li>
      </ul>
      <ul class="s-main-view-admin-bottom-list g-flex-align-center">
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom1.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.total_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">总金额</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom2.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.ben_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">本金</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom3.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.base_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">基础佣金</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom4.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.vat_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">增值佣金</p>
        </li>
        <li class="s-main-view-admin-bottom-item g-flex-column g-flex-align-center">
          <img src="/img/adminbom2.png" alt="">
          <p class="s-main-view-admin-bottom-item-money">¥{{formData.service_price | filtersFixed }}</p>
          <p class="s-main-view-admin-bottom-item-title">技术服务费</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import { apiGetAdminTotalReport } from '@/utils/api.js'
// 一天的时间戳
var oneDayTamp = 24 * 60 * 60 * 1000
// 昨天 00:00:00 date对象
var yesterDay00 = new Date(new Date(new Date().toLocaleDateString()).getTime()- oneDayTamp)
// // 昨天 23:59:59 date对象
var yesterDay2359= new Date(new Date(new Date().toLocaleDateString()).getTime() - oneDayTamp + (24 * 60 * 60 * 1000 - 1))
export default {
  data() {
    return {
      form: {
        date_time: []
      },
      timeList: [
        { name: '近30天', start_time: (parseInt((new Date().getTime() - (30 * oneDayTamp)) / 1000)),  end_time: (parseInt(new Date().getTime() / 1000))},
        { name: '近15天', start_time: (parseInt((new Date().getTime() - (15 * oneDayTamp)) / 1000)),  end_time: (parseInt(new Date().getTime() / 1000)) },
        { name: '近7天', start_time: (parseInt((new Date().getTime() - (7 * oneDayTamp)) / 1000)),  end_time: (parseInt(new Date().getTime() / 1000)) },
        { name: '昨天', start_time: parseInt(yesterDay00.getTime() / 1000) ,  end_time: (parseInt(yesterDay2359.getTime() / 1000)) },
        { name: '自定义', start_time: 0,  end_time: 0 },
      ],
      tabIndex: 0,
      formData: {
        base_price: '', //基础佣金
        ben_price: '',//本金
        buy_price:'',//买家返款佣金
        refund_price: '',//退款金额
        return_price: '',//推广返还佣金
        service_price: '',//技术服务费
        taskNums: '',//任务数量
        taskUndone: '', //未完成订单数量
        taskOrderEnd: '',//任务订单已完成数量
        taskOrdercancel: '',//任务订单已取消数量
        taskOrderRelease: '',//任务订单发布数量 总数
        total_price: '',//总金额
        vat_price: '',//增值佣金
        profit: '', //总利润
      }
    }
  },
  created() {
    this.apiGetAdminTotalReportHandel()
  },
  filters: {
    filtersFixed(val) {
      return Number(val).toFixed(2)
    }
  },
  methods: {
    // 获取首页数据
    async apiGetAdminTotalReportHandel() {
      let loading = this.$loading({
          text: '拼命加载中',
          background: 'rgba(0, 0, 0, 0.5)'
      })
      const { success, data } = await apiGetAdminTotalReport(this.timeList[this.tabIndex]).catch(loading.close())
      loading.close()
      if(!success) return
      
      this.formData = data.data
      // 未完成
      this.formData.taskUndone = Number(data.data.taskOrderRelease) - Number(data.data.taskOrdercancel) -  Number(data.data.taskOrderEnd)
      console.log(data)
    },
    
    timePinkerChange(val) {
      this.timeList[this.timeList.length - 1].start_time = parseInt(val[0].getTime() / 1000)
      this.timeList[this.timeList.length - 1].end_time = parseInt(val[1].getTime() / 1000)
      this.apiGetAdminTotalReportHandel()
    },

    timeSelect(item, index) {
      this.tabIndex = index
      if(index != 4) return this.apiGetAdminTotalReportHandel()
    } 
  }
}
</script>

<style lang='scss'>
.s_main_view_admin {
  width: 100%;
  height: 100%;
  background: url("/img/admin_bg.png") no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 50px 0 50px;
  .s-main-view-admin-top {
    padding-top: 10px;
    .s-main-view-admin-top-list {
      background: $white;
      border: 1px solid #E5E5E5;
      height: 50px;
      border-radius: 10px;
      overflow: hidden;
      .s-main-view-admin-top-item {
        height: 100%;
        width: 80px;
        color: #A3A4A4;
        font-size: 15px;
        cursor: pointer;
        &:hover {
          background: #f7f8fc;
        }
        border-right: 1px solid #E5E5E5;
        &:nth-last-of-type(1) {
          border-right: 0;
        }
        &.s-main-view-admin-top-item-active {
          background: #E3E6F9;
          color: #8293FF;
        }
      }
      .s-main-view-admin-top-time-pick {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .s-main-view-admin-middle {
    padding-top: 20px;
    .s-main-view-admin-middle-list {
      .s-main-view-admin-middle-item {
        flex: 1;
        margin-right: 30px;
        background: $white;
        min-height: 220px;
        max-height: 240px;
        padding-top: 40px;
        padding-left: 20px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 6px 18px 8px rgba(194, 197, 251, 0.2);
        }
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        .s-main-view-admin-middle-item-title {
          font-size: 24px;
          color: $blue;
          font-weight: bold;
        } 
        .s-main-view-admin-middle-item-num {
          padding-top: 12px;
          font-size: 34px;
          padding-right: 30px;
          color: #02242E;
          font-weight: bold;
          .s-main-view-admin-middle-item-num-val {
            flex: 1;
            padding-right: 4px;
          }
          img {
            width: 85px;
            object-fit: cover;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .s-main-view-admin-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    .s-main-view-admin-bottom-list {
      margin-top: 20px;
      .s-main-view-admin-bottom-item {
        flex: 1;
        margin-right: 40px;
        background: $white;
        min-height: 300x;
        max-height: 300px;
        padding-top: 10px;
        cursor: pointer;
        border-radius: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 6px 18px 8px rgba(194, 197, 251, 0.2);
        }
        img {
          width: 124px;
          height: 90px;
          object-fit: cover;
          vertical-align: middle;
        }
        .s-main-view-admin-bottom-item-money {
          padding-top: 30px;
          font-size: 30px;
          font-weight: bold;
        }
        .s-main-view-admin-bottom-item-title {
          padding-top: 30px;
          padding-bottom: 20px;
          font-weight: bold;
          color: $blue;
          font-size: 22px;
          text-align: center;
        }
      }
    }
  }
}
</style>