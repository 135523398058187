<template>
 <div class="v_main_view">
   <!-- 商家 -->
   <main-view-store v-if="$global.adminUser.type == 2"/>

   <!-- 管理员 -->
   <main-view-admin v-if="$global.adminUser.type >= 4"/>
 </div>
</template>

<script>
import MainViewStore from './MainViewStore.vue'
import MainViewAdmin from './MainViewAdmin.vue'
export default {
  components: { MainViewStore, MainViewAdmin },
  data() {
    return {

    }
  }
}
</script>

<style lang='scss'>
.v_main_view {
  height: 100%;
}
</style>